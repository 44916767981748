import { ActiveProgram } from '../../../services/util/ActiveProgram';
import omit from 'lodash.omit';
import { ValidationControllerFactory, ValidationController } from 'aurelia-validation';
import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-dependency-injection';

import { AlertService } from '../../../services/util/Alert';

import { ValidatedProjectFile } from '../../../types/ProjectFile';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { LogManager, bindable } from 'aurelia-framework';
import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';

const log = LogManager.getLogger('dfp:project-file');

export interface IEditActivateParams {
    files: ValidatedProjectFile[];
    folders: FileFolder[];
    galleryIndex?: number;
}

@autoinject
export class ProjectFileEdit {
    @bindable batchTag: boolean;
    @bindable disabled = true;

    file: ValidatedProjectFile;
    files: ValidatedProjectFile[];
    galleryIndex?: number;
    folders: FileFolder[];
    destinationFolder: string;
    validation: ValidationController;
    tags: string[];
    newTag: string;

    constructor(
        private dialog: DialogController,
        private validationFactory: ValidationControllerFactory,
        private alerts: AlertService,
        private program: ActiveProgram,
        private api: DatafiProAPI,
    ) { }

    activate(model: IEditActivateParams) {
        this.files = model.files;
        this.folders = model.folders;

        if (!model.galleryIndex && model.galleryIndex !== 0) {
            this.batchTag = true;
            this.file = new ValidatedProjectFile({}, this.api.files);
            const tagSet = this.files.reduce((prev: Set<string>, current: ValidatedProjectFile) => {
                const tags = current.user_tags?.split(',') || [];
                tags.forEach((tag) => prev.add(tag));
                return prev;
            }, new Set());
            this.tags = Array.from(tagSet);
            this.validation = this.validationFactory.createForCurrentScope();
        } else {
            this.galleryIndex = model.galleryIndex;
            this.changeFile();
        }
    }

    changeFile(direction?: 'next' | 'prev') {
        if (direction) {
            this.galleryIndex = direction === 'next' ? this.galleryIndex + 1 : this.galleryIndex - 1;
            if (this.galleryIndex < 0) this.galleryIndex = this.files.length - 1;
            if (this.galleryIndex > this.files.length - 1) this.galleryIndex = 0;
        }

        this.file = new ValidatedProjectFile(this.files[this.galleryIndex], this.api.files);
        this.tags = this.file.user_tags?.split(',') || [];
        this.validation = this.validationFactory.createForCurrentScope();

        if (!this.file._blobs.full) {
            this.file.getFull();
        }
    }

    submit() {
        return this.validation.validate()
            .then((result) => {

                if (!result.valid) {
                    throw new Error('Form is not valid');
                }

                this.file.user_tags = this.tags?.join(',');
                if (this.destinationFolder) {
                    this.file.program_folder = this.destinationFolder;
                }

                const patchFiles = this.batchTag ? this.files : [this.file];

                return Promise.all(patchFiles.map((file) => {
                    return this.api.projectFiles.patch(file.id, this.file);
                }));
            })
            .then((result: ValidatedProjectFile[]) => {
                for (let i = 0; i < result.length; i++) {
                    Object.assign(this.files[i], omit(result[i], ['geom']));
                }

                this.alerts.create({
                    label: this.batchTag ? 'Files Saved' : 'File Saved',
                    level: 'success',
                    dismissable: true,
                });
                const sendData = { files: this.batchTag ? this.files : [this.file] };
                if (!this.batchTag) sendData['index'] = this.galleryIndex;
                this.dialog.ok(sendData);
            })
            .catch((e) => {
                log.warn(e);
                this.alerts.create({
                    label: e,
                    level: 'error',
                    dismissable: true,
                });
            });
    }

    deleteFile() {
        return this.api.projectFiles.remove(this.file.id)
            .then(() => {
                this.alerts.create({
                    label: 'File Removed',
                    level: 'success',
                    dismissable: true,
                });
                this.dialog.ok({ index: this.galleryIndex });
            })
            .catch((e) => {
                log.warn(e);
                this.alerts.create({
                    label: e,
                    level: 'error',
                    dismissable: true,
                });
            });
    }
}
